import React from 'react';

const HeaderSVG = ({top}) => (
    <svg width="1200" height="1000" viewBox="0 0 1200.0001 1000" id="svg2" version="1.1" style={{position: "absolute", top: top}}>
        <defs id="defs4">
            <linearGradient id="linearGradient4528">
                <stop id="stop4530" offset="0" style={{stopColor:"#ffffff",stopOpacity:0}} />
                <stop style={{stopColor:"#f8e3e3",stopOpacity:0}} offset="0.95274687" id="stop4532" />
                <stop id="stop4534" offset="0.98379183" style={{stopColor:"#f8f2e3",stopOpacity:0.75294119}} />
                <stop id="stop4536" offset="1" style={{stopColor:"#f8f4e3",stopOpacity:1}} />
            </linearGradient>
            <linearGradient id="linearGradient4441">
                <stop style={{stopColor:"#f8f4e3",stopOpacity:1}} offset="0" id="stop4443" />
                <stop id="stop4500" offset="0.51143622" style={{stopColor:"#f8f4e3",stopOpacity:0.67532468}} />
                <stop style={{stopColor:"#f8f4e3",stopOpacity:0}} offset="1" id="stop4445" />
            </linearGradient>
            <linearGradient id="linearGradient4161">
                <stop style={{stopColor:"#ffffff",stopOpacity:0}} offset="0" id="stop4163" />
                <stop id="stop4169" offset="0.95274687" style={{stopColor:"#e4e3f8",stopOpacity:0}} />
                <stop style={{stopColor:"#e3f8f4",stopOpacity:0.75294119}} offset="0.98379183" id="stop4171" />
                <stop style={{stopColor:"#f8f4e3",stopOpacity:1}} offset="1" id="stop4165" />
            </linearGradient>
            <radialGradient id="radialGradient4167" xlinkHref="#linearGradient4161" cx="1115.5502" cy="1522.8805" fx="1115.5502" fy="1522.8805" r="600.00006" gradientTransform="matrix(-1.6564049,0,5.0182972e-4,-0.68077414,2449.6068,151.17672)" gradientUnits="userSpaceOnUse" />
            <radialGradient id="radialGradient4167-2" xlinkHref="#linearGradient4528" cx="1117.1265" cy="1516.4803" fx="1117.1265" fy="1516.4803" r="600.00006" gradientTransform="matrix(-1.6564051,0,5.0182978e-4,0.68077422,2449.6071,-878.52551)" gradientUnits="userSpaceOnUse" />
            <linearGradient id="linearGradient4447" xlinkHref="#linearGradient4441" x1="39.048885" y1="-398.12396" x2="2.9132488" y2="-398.12396" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1.0016668,0,0,1.0208473,0,6.1074301)" />
            <linearGradient id="linearGradient4447-3" xlinkHref="#linearGradient4441" x1="39.048885" y1="-398.12396" x2="2.9132488" y2="-398.12396" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-1.0016668,0,0,1.0208474,1200.0001,6.1075429)" />
        </defs>
        <g id="layer1" transform="translate(0,947.63754)" style={{opacity:1}}>
        <path
            //style={{fill:"url(#radialGradient4167)",fillOpacity:1,stroke:"none",strokeWidth:1.5,strokeMiterlimit:4,strokeDasharray:"none",strokeDashoffset:0,strokeOpacity:1}}
            style={{fill:"url(#radialGradient4167)",fillOpacity:1,stroke:"none",strokeWidth:1.5,strokeMiterlimit:4,strokeDasharray:"none",strokeDashoffset:0,strokeOpacity:1}}
            d="M 1192.5665,-591.34772 A 592.56646,144.13073 0 0 1 600.00008,-447.21699 592.56646,144.13073 0 0 1 7.4336267,-591.34772 592.56646,144.13073 0 0 1 600.00008,-735.47845 592.56646,144.13073 0 0 1 1192.5665,-591.34772 Z"
            id="path4151" />
        <path
            style={{opacity:1,fill:"url(#radialGradient4167-2)",fillOpacity:1,stroke:"none",strokeWidth:1.5,strokeMiterlimit:4,strokeDasharray:"none",strokeDashoffset:0,strokeOpacity:1}}
            d="M 1192.5666,-136.00102 A 592.56653,144.13075 0 0 0 600.00004,-280.13176 592.56653,144.13075 0 0 0 7.4335305,-136.00102 592.56653,144.13075 0 0 0 600.00004,8.1297338 592.56653,144.13075 0 0 0 1192.5666,-136.00102 Z"
            id="path4151-1" />
        <path
            style={{opacity:1,fill:"url(#linearGradient4447)",fillOpacity:1,stroke:"none",strokeWidth:1.5,strokeMiterlimit:4,strokeDasharray:"none",strokeDashoffset:0,strokeOpacity:1}}
            d="m 0,-598.12327 0,475.83648 9.1421658,0 A 593.55421,147.13549 0 0 1 7.445984,-130.68686 593.55421,147.13549 0 0 1 601.00007,-277.82245 l 0,-172.61092 A 593.55407,147.13545 0 0 1 7.445984,-597.56898 a 593.55407,147.13545 0 0 1 0.1115137,-0.55429 l -7.5574977,0 z"
            id="rect4439" />
        <path
            style={{opacity:1,fill:"url(#linearGradient4447-3)",fillOpacity:1,stroke:"none",strokeWidth:1.5,strokeMiterlimit:4,strokeDasharray:"none",strokeDashoffset:0,strokeOpacity:1}}
            d="m 1200.0001,-598.12319 0,475.8365 -9.1421,0 a 593.55425,147.1355 0 0 0 1.6963,-8.40007 593.55425,147.1355 0 0 0 -593.55423,-147.1356 l 0,-172.61092 a 593.55411,147.13546 0 0 0 593.55423,-147.13562 593.55411,147.13546 0 0 0 -0.1116,-0.55429 l 7.5574,0 z"
            id="rect4439-9"  />
        </g>
    </svg>
);

export default HeaderSVG;