import React from 'react';

import SharedStyles from './shared-styles.js';
import headshotImage from '../images/headshot.jpg';
import PostListItem from './post-list-item.js';
import HeaderSVG from './header-svg.js';

// TODO: favicon

export default ({data}) => (
    <div style={SharedStyles.container}>
        <div style={styles.headshot}>
            <img src={headshotImage} style={styles.headshotImg} />
        </div>
        <header style={SharedStyles.header}>
            <HeaderSVG top={0} />
            <div style={{...SharedStyles.headerBody, paddingTop: 470}}>
                <h1 style={SharedStyles.h1}>Hi. I'm Tom.</h1>
                <p style={SharedStyles.p}>I live in San Jose. I'm a father, an engineering manager, a data scientist, and a software engineer.</p>
                <p style={SharedStyles.p}>I work at <a title="Khan Academy" href="https://www.khanacademy.org" target="_blank" style={styles.a}>Khan Academy</a>,
                    a non-profit working to provide a free world-class
                    education to anyone, anywhere.
                </p>
                <p style={SharedStyles.p}>
                    <a title="GitHub" href="http://github.com/tomyedwab" target="_blank" style={styles.a}>
                        <svg viewBox="0 0 512 512" width="40px" height="40px">
                            <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"></path>
                        </svg>
                    </a>
                </p>
            </div>
        </header>

        <article style={SharedStyles.article(true)}>
            <h1 style={SharedStyles.h1}>I have a blog: Arguing with Algorithms.</h1>
            <p style={SharedStyles.p}>You can read the latest post here:</p>

            <PostListItem post={data.allMarkdownRemark.edges[0].node} />

            <p style={SharedStyles.p}>Or see the <a href="/blog" style={SharedStyles.a}>full archive</a>.</p>
        </article>

        <footer style={SharedStyles.footer}>
            ~ &copy; 2019 Tom Yedwab ~
        </footer>
    </div>
);

const styles = {
    headshot: {
        backgroundColor: "#1f1518",
    },

    headshotImg: {
        display: "block",
        margin: "0 auto",
    },
};

export const query = graphql`
query {
    allMarkdownRemark(
        sort: {
            fields: [frontmatter___sortdate]
            order: DESC
        }
    ) {
        edges {
            node {
                fileAbsolutePath
                frontmatter {
                    title
                    dateline
                    sortdate
                }
                excerpt
                html
            }
        }
    }
}`;
