import React from 'react';
import {Link} from 'gatsby';

import {extractHeader} from './util.js';

// TODO: Links don't push history for some reason?

export default ({post, size}) => {
    const [_, headerImage] = extractHeader(post.html);
    const slug = post.fileAbsolutePath.split("/").pop().split(".md")[0];

    return <div style={styles.outer(size === "small", headerImage)}>
        <div style={styles.inner(size === "small")}>
            <p style={styles.title(size === "small")}>
                <Link to={`/blog/${slug}`} style={styles.a}>
                    {post.frontmatter.title}
                </Link>
            </p>
            <p style={styles.dateline(size === "small")}>
                {post.frontmatter.dateline}
            </p>
            <p style={styles.desc(size === "small")}>
                {post.excerpt}
                ... <Link to={`/blog/${slug}`} style={styles.a}>(more)</Link>
            </p>
        </div>
    </div>;
}

const styles = {
    outer: (isSmall, headerImage) => ({
        background: `url(${headerImage})`,
        backgroundRepeat: "no-repeat",
        borderRadius: "10% 10% 10% 10%",
        width: isSmall ? 480 : 960,
        height: isSmall ? 220 : 300,
        display: "inline-block",
        margin: isSmall ? 10 : "64px auto",
        overflow: "hidden",
    }),

    inner: (isSmall) => ({
        backgroundImage: "linear-gradient(to right, rgb(0, 0, 0, 0), rgb(0, 0, 0, 0.8), rgb(0, 0, 0, 0.81), rgb(0, 0, 0, 0.82))",
        width: "100%",
        height: "100%",
        padding: isSmall ? "2px 18px" : "24px 48px",
        position: "relative",
        boxSizing: "border-box",
        color: "#F8F4E3",
        boxShadow: "inset -1px 2px 8px #F8F4E3",
        borderRadius: "10% 10% 10% 10%",
    }),

    title: (isSmall) => ({
        fontSize: isSmall ? "24px" : "36px",
        fontWeight: "bold",
        textAlign: "right",
        textShadow: "#000 -2px 2px 3px",
    }),

    dateline: (isSmall) => ({
        fontSize: "12px",
        textAlign: "right",
        marginTop: isSmall ? 28 : 48,
        marginBottom: 0,
    }),

    desc: (isSmall) => ({
        fontSize: "18px",
        textAlign: "right",
        position: "absolute",
        right: 48,
        width: 400,
    }),

    a: {
        color: "#F8F4E3",

        ":visited": {
            color: "#F8F4E3",
        },
    },
};
