
/* https://coolors.co/1f1518-397367-f8f4e3-72a1e5-63ccca */
/* Colors:
  Dk brown #1f1518
  Dk green #397367
  Light tan #f8f4e3
  Sky blue #73a1e5
  Lt green #63ccca
*/

// TODO: More responsive at mobile sizes

const SharedStyles = {
    container: {
        height: "100%",
        margin: "0 auto",
        position: "relative",
        width: 1200,
    },

    header: {
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
    },

    headerBody: {
        color: "#1f1518",
        left: 0,
        paddingLeft: 40,
        paddingRight: 40,
        position: "absolute",
        right: 0,
        textAlign: "center",
    },

    h1: {
        fontSize: "28px",
        fontWeight: "bold",
        marginBlockEnd: 20,
        marginBlockStart: 20,
        marginInlineStart: 0,
        marginInlineEnd: 0,
    },

    h2: {
        fontSize: "14px",
        marginBlockEnd: 20,
        marginBlockStart: 20,
        marginInlineStart: 0,
        marginInlineEnd: 0,
    },

    p: {
        marginBlockStart: 18,
        marginBlockEnd: 18,
    },

    a: {
        color: "#397367",
        ":visited": {
            color: "#397367",
        },
    },

    article: (first) => ({
        paddingTop: first ? 280 : 0,
        textAlign: "center",
        color: "#72a1e5",
    }),

    footer: {
        fontSize: "12px",
        height: 20,
        textAlign: "center",
        color: "#72a1e5",
    },
};

export default SharedStyles;