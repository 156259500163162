export function extractHeader(html) {
    const startP = html.indexOf("<p>");
    const endP = html.substring(startP + 3).indexOf("</p>") + 3;

    const header = html.substring(startP + 3, endP);
    const matches = header.match(/href=\"(.+)\"/);
    if (matches && matches.length > 1) {
        const headerHref = matches[1];
        const body = html.substring(endP + 4);
        return [body, headerHref];
    }
    return [html, ""];
}